export function doIntercept2FA(errorData: any, callback: () => void) {
  if (errorData.errors.includes("2fa:begin")) {
    callback();
    return true;
  }
  return false;
}

export function is2FAInvalid(errorData: any) {
  return errorData.errors.includes("2fa:failure");
}