import React, {useEffect, useState} from "react";
import check from "#/src/assets/img/check.png";
import {LoadableButton} from "#/src/components/LoadableButton";
import styled from "@emotion/styled";

export enum TwoFactorStatus {
  INVALID = "INVALID"
}

interface Props {
  id: string,
  isOpen: boolean,
  onHide: () => void,
  onCodeProvide: (code: TwoFactorCodeReturn) => Promise<TwoFactorStatus | undefined>,
  supportsBackupCodes?: boolean,
  cancellable?: boolean
}

export interface TwoFactorCodeReturn {
  code: string,
  type: 'TOTP' | 'BACKUP_CODES'
}

interface StyledProps {
  border: boolean
}

const AcceptButton = styled(LoadableButton)<StyledProps>`
    ${props => props.border && `
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  `}
`;

const CancelButton = styled.button`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: hsla(0, 0%, 0%, .5);
`;

export function TwoFactorModal({id, isOpen, onHide, onCodeProvide, supportsBackupCodes = true, cancellable = true}: Props) {
  const [type, setType] = useState<'TOTP' | 'BACKUP_CODES'>('TOTP');
  const [invalidCode, setInvalidCode] = useState(false);
  useEffect(() => {
    if (isOpen) {
      document.getElementById("2faCode")?.focus();
    }
  }, [isOpen]);

  async function onClick() {
    const code = (document.getElementById(`2faCode${id}`) as HTMLInputElement).value;
    if (code == "") {
      setInvalidCode(true);
      return;
    }
    return onCodeProvide({code, type}).then((status) => {
      if(!status) {
        onHide();
        return;
      }
      if (status == TwoFactorStatus.INVALID) {
        setInvalidCode(true);
      }
    });
  }

  function onInput(e: React.KeyboardEvent<HTMLInputElement>) {
    setInvalidCode(false);
    if (e.key === "Enter") {
      document.getElementById(`2faVerifyButton${id}`).click();
    }
  }

  return <>
    <div id={`twoFactor${id}`} className={`modal fade show ${isOpen ? "d-block" : ""}`}>
      <div className="col-xl-3 mx-auto my-auto">
        <div className="panel mt-4 text-center">
          <div className="text-center mb-4">
            <img src={check} alt="Done"/>
          </div>
          <div className="panel-title">
            <h2 className="basic-headline mb-2">
              Weryfikacja dwuetapowa
            </h2>
            {type == 'TOTP' && <p>By kontynuować podaj 6-cyfrowy kod z aplikacji uwierzytelniającej.</p>}
            {type == 'BACKUP_CODES' && <p>By kontynuować podaj 6-znakowy kod zapasowy.</p>}
            <div className="mb-3">
              <label htmlFor={`2faCode${id}`} className="form-label">Kod weryfikacyjny</label>
              <input type={type == 'TOTP' ? "number" : "text"} className="form-control" id={`2faCode${id}`} placeholder="Kod do weryfikacji" onKeyDown={onInput}/>
              {invalidCode && <div className={"two-factor-text-invalid mt-2 ms-1"}>Kod jest nieprawidłowy</div>}
            </div>
          </div>

          <AcceptButton border={cancellable} id={`2faVerifyButton${id}`} className={`btn btn-primary w-${cancellable ? "50" : "100"} my-2`}
                        onClick={onClick}>Zweryfikuj</AcceptButton>
          {cancellable && <CancelButton className="btn btn-secondary w-50 my-2" onClick={onHide}>Anuluj</CancelButton>}
          {supportsBackupCodes && type == 'TOTP' && <div className="sign-up">
            <span>Użyj <a href={"#"} onClick={() => setType('BACKUP_CODES')}>kodów zapasowych</a></span>
          </div>}
          {type == 'BACKUP_CODES' && <div className="sign-up">
            <span>Użyj <a href={"#"} onClick={() => setType('TOTP')}>aplikacji uwierzytelniającej</a></span>
          </div>}
        </div>
      </div>
    </div>
    {isOpen && <Backdrop/>}
  </>
}